import AboutUs from "../components/home/aboutUs";
import ContactUs from "../components/home/contactUs";
import LandingPage from "../components/home/landingPage";
import Services from "../components/home/services";
import Footer from "./footer";
import TeamSuvidha from './ourTeam'

function Home() {
    return(
        <>
            <LandingPage/>
            <Services/>
            <AboutUs/>
            <ContactUs/>
            <TeamSuvidha />
            <Footer/>
        </>
    )
}

export default Home;