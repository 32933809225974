import Canteen from "../assets/images/dashboard/canteen.png";
import BuyAndSell from "../assets/images/dashboard/buysell.png";
import AutoShare from "../assets/images/dashboard/autoshare.png";
import QnA from '../assets/images/dashboard/qna.png'
import { Link, useNavigate } from "react-router-dom";

function Dashboard() {
  const apps = [
    {
      name: "Canteen",
      imgSrc: Canteen,
      link: "/canteen",
      blocked: false,
    },
    {
      name: "Buy and Sell",
      imgSrc: BuyAndSell,
      link: "/buyAndSell",
      blocked: false,
    },
    {
      name: "Auto Share",
      imgSrc: AutoShare,
      link: "/autoShare",
      blocked: false,
    },
    {
      name: "QnA",
      imgSrc: QnA,
      link: "/QnA",
      blocked: false,
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className="h-4/5">
        <div className="text-center flex h-1/3 justify-around items-center">
          <div className="text-center mt-10 text-3xl font-bold">
            Select your service
          </div>
        </div>

        <div className="text-center grid grid-cols-1 md:grid-cols-3 md:gap-5 justify-around mx-20 items-center mt-10 mb-10">
          {apps.map((app, index) => (
            <div key={index} onClick={() => {app.blocked ? <></> : navigate(`${app.link}`)} }>
              <div
                // to={`${app.link}`}
                className="scale-90 md:scale-100 border-2 rounded-3xl flex flex-col justify-center sm:mt-10 mx-5 md:mx-5 items-center border-blue-100  p-10 text-xl hover:border-blue-200 cursor-pointer hover:bg-blue-50 transition delay-100 hover:scale-90 md:hover:scale-110"
              >
                <img
                  src={app.imgSrc}
                  className="object-scale-down h-28 w-32 mb-5 "
                  alt={`${app.name}`}
                />
                {app.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
