import http from './httpService';
import jwtDecode from 'jwt-decode';
import httpService from './httpService';
const apiLogin = "/login";
const apiLogout = "/logout";
const tokenKey = "token";
const roleKey = "role";
const userid = "userid"

http.setJWT(httpService.setJWT());

let rememberMeAuth = true;

export async function login(email, password, rememberMe) {
    const {data : res} = await http.post(apiLogin, { email, password });

    rememberMeAuth = rememberMe;
    if(rememberMeAuth){
        localStorage.setItem(tokenKey, res.token);
        localStorage.setItem(roleKey, res.user.role);
        localStorage.setItem(userid, res.user._id);
        localStorage.setItem("profile", res.user.isprofileupdated);
        if(res.user.image){
            localStorage.setItem("profileImage", res.user.image);
        }
    }
    else{
        sessionStorage.setItem(tokenKey, res.token);
        sessionStorage.setItem(roleKey, res.user.role);
        sessionStorage.setItem(userid, res.user._id);
        localStorage.setItem("profile", res.user.isprofileupdated);
        localStorage.setItem("profileImage", res.user.image);
    }
    setTimeout(() => {
        sessionStorage.removeItem(tokenKey);
        sessionStorage.removeItem(roleKey);
        sessionStorage.removeItem(userid);
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(roleKey);
        localStorage.removeItem(userid);
        localStorage.removeItem("profile");
        localStorage.removeItem("profileImage");
      },  10* 60 * 1000); // 10 minutes in milliseconds
    return res;
}

export async function verifyUser() {
    if(rememberMeAuth){
        const token = localStorage.getItem(tokenKey);
        localStorage.setItem(tokenKey, token);
    }
    else{
        const token = sessionStorage.getItem(tokenKey);
        sessionStorage.setItem(tokenKey, token);
    }
}

export async function loginWithJwt(jwt) {
    if(rememberMeAuth)
        localStorage.setItem(tokenKey, jwt);
    else
        sessionStorage.setItem(tokenKey, jwt);
}

export async function logout() {
    await httpService.get(apiLogout);
    if(rememberMeAuth){
        localStorage.clear();
        return true;
    }
    else{
        sessionStorage.clear();
        return true;
    }

    // if(rememberMeAuth){
    //     const res = await httpService.get(apiLogout);

    //     if(res.data.success === true){

    //         localStorage.clear();
    //         return true;
    //     }
    //     else{
    //         alert("Logout Failed");
    //         return false;
    //     }
    // }
    // else{
    //     const res = await httpService.get(apiLogout);
    //     if(res.data.success === 200){
    //         sessionStorage.removeItem(tokenKey);
    //         sessionStorage.removeItem(userid);
    //         sessionStorage.removeItem(roleKey);
    //     }
    //     else
    //         alert("Logout Failed");
    //     return http.post(apiLogout);
    // }
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);

        return jwtDecode(jwt).id;
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export function getRole() {
    return localStorage.getItem(roleKey);
}
export function getId() {
    return localStorage.getItem(userid);
}

const authService = { login, loginWithJwt, logout, getCurrentUser, getJwt, verifyUser, getRole,getId };

export default authService;