import React, { useState } from "react";
import { Link } from "react-router-dom";
import authService from "../../services/authService";
import buyAndSellService from "../../services/buyAndSellService";
import Loader from "../../common/loader";
import LoginPromt from "../../container/loginPrompt";
import { getId } from '../../services/authService';
import AutoService from "../../services/AutoServices";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, WhatsappIcon, EmailIcon } from "react-share";
import QnAService from "../../services/QnAService";
function QuestionCard({ questiondata }) {
  const [loader, setLoader] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [enquiry, setEnquiry] = useState(false);
  const [enquiryData, setEnquiryData] = useState("");
  const [enquiryLoader, setEnquiryLoader] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [isUpvoted, setisUpvote] = useState(false);
  const [share, setShare] = useState(false);
  const [copy, setCopy] = useState(false);
  const [save, setSave] = useState(questiondata?.bookmark.includes(getId()) ? true : false)

  console.log(questiondata)
  const saveQues = async (questionid) => {

    const res = await QnAService.saveQuestion({ questionid });
    console.log(res.data)


    setSave(!save);
    // setNoOfRiders(res.data.cab[0].no_of_riders)

  };
  const downvote = () => {

  };

  const upvote = () => {

  };


  const toggleLoginPrompt = () => {
    console.log("toggle");
    setLoginPrompt(false);
  };

  const deleteQuestion = async (id) => {
    setLoader(true);
    const data = {
      requestid: `${id}`
    };

    try {
      const res = await QnAService.deleteQuestion(data);
      console.log(res)

      if (res.data.success) {
        setDeleted(true);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const enquireProduct = async (id) => {

    if (!localStorage.getItem('token')) {
      setLoginPrompt(true);
      return;
    }

    if (enquiryData.length < 10) {
      setEnquiryLoader(true);
      const res = await AutoService.getRequestEnquire(id);
      console.log(res.data);
      if (res.data.phoneNo) {
        setEnquiryData(res.data.phoneNo);
        setEnquiry(true);
        setEnquiryLoader(false);
      }
    }
  };

  const openLoginPrompt = () => {
    if (!localStorage.getItem("token")) {
      setLoginPrompt(true);
      return;
    }
  };

  function getDateXDaysAgo(date) {
    const currentDate = new Date();

    let diff = currentDate - new Date(date);

    let diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  let answer;
  if (questiondata?.answer.length == 0) {
    answer = (
      <>
        <Link
          onClick={() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }}
          to={`/QnA/${questiondata._id}`}
        >
          <div class="text-base text-white border rounded-lg p-1 mt-5   text-white bg-indigo-600 text-center">
            Be the first One to answer this question
          </div>
        </Link>
      </>
    );
  } else {
    answer = (
      <>
        <div className="p-5">
          <div className="flex mb-3 items-center">
            <img src={questiondata?.answer[0].ansUser.image} alt="Profile Pic" class=" object-cover w-10 h-10 mx-4 rounded-full sm:block" />
            <div className="flex flex-col">
              <span className="text-sm textcolor font-black">
                {questiondata?.answer[0].ansUser.name}
              </span>
              <span className="text-sm">
                {" "}
                {questiondata?.answer[0].ansUser.email}{" "}
              </span>
            </div>
          </div>
          <div
            className="h-auto overflow-y-hidden box text-sm"
            style={{ minHeight: "30px", maxHeight: "150px" }}
          >
            <div dangerouslySetInnerHTML={{ __html: questiondata?.answer[0].answerDes }}></div>

          </div>
          <div className="float-right text-base textcolor ">Read More</div>
        </div>
      </>
    );
  }

  let Sharelogo;
  if (share) {
    Sharelogo = (
      <>
        <div className="flex justify-end mt-5">
          <div className="pr-4 pl-4  ">
            <WhatsappShareButton
              title={questiondata?.question}
              url={`https://www.campussuvidha.com/QnA/${questiondata?._id}`}
            >
              <WhatsappIcon size={30}></WhatsappIcon>
            </WhatsappShareButton>
          </div>
          <div className="pr-4 pl-4  ">
            <FacebookShareButton
              title={questiondata?.question}
              url={`https://www.campussuvidha.com/QnA/${questiondata?._id}`}
            >
              <FacebookIcon size={30}></FacebookIcon>
            </FacebookShareButton>
          </div>
          <div className="pr-4 pl-4  ">
            <EmailShareButton
              title={questiondata?.question}
              url={`https://www.campussuvidha.com/QnA/${questiondata?._id}`}
            >
              <EmailIcon size={30}></EmailIcon>
            </EmailShareButton>
          </div>
          <div className="pr-4 pl-4  ">
            <CopyToClipboard
              text={`https://www.campussuvidha.com/QnA/${questiondata?._id}`}
              onCopy={() => {
                setCopy(true);
              }}
            >
              <button>
                <i class="fa fa-copy"></i>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </>
    );
  }
  let editbutton;
  if (questiondata?.user._id == getId()) {
    editbutton = (

      <>

        {/* <i class="fa fa-trash text-red-600  mr-4 hover:cursor-pointer" onClick={()=>deleteQuestion(questiondata?._id)}></i> */}

        <Link to={`/QnA/editQuestion/${questiondata?._id}`}>

          <i class="fa fa-pencil text-blue-600  mr-2"></i>
        </Link>



      </>

    )
  }
  return (
    <>
      <div class="md:w-2/3 mb-10 w-full flex animationtop flex-col bg-white shadow-2xl rounded-lg overflow-hidden p-3">
        <div className="mb-1">

          <div className="flex justify-between ">
            <div className="flex items-center">



              <div className="flex ">
                <img src={questiondata?.user.image} alt="Profile Pic" class=" object-cover w-10 h-10 mx-4 rounded-full sm:block" />

                <p class="font-bold text-gray-700 cursor-pointer dark:text-gray-200">{questiondata?.user.name}
                  <p class="text-sm text-gray-400">{questiondata?.user.email}</p>
                </p>
              </div>
            </div>
            <div className="flex items-right">

              {editbutton}
            </div>
          </div>
          <Link
            onClick={() => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
            }}
            to={`/QnA/${questiondata?._id}`}
          >
            <div class="font-black text-xl  mt-5">
              {questiondata?.question}{" "}
              <span className="text-2xl font-bold text-red-600">?</span>
            </div>
          </Link>
          <div class="text-xs mt-2 textcolor font-bold font-semibold items-center underline">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            &nbsp;Answers: &nbsp;{questiondata?.answer.length}
            &nbsp;&nbsp;&nbsp;Posted-on: &nbsp;
            {questiondata?.date.substring(0, 10)}
          </div>

          <br></br>
          <hr></hr>
          <div className="flex justify-between  mt-2">

            {localStorage.getItem("token") ?
              <>
                <div
                  class="text-lg mr-2 p-1 cursor-pointer"
                  onClick={() => saveQues(questiondata?._id)}
                >

                  {save ? <>
                    <i class="fa fa-bookmark" aria-hidden="true"></i>&nbsp;{" "}
                    &nbsp;Saved
                  </> : <>
                    <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;{" "}
                    &nbsp;Save
                  </>}

                </div>
              </> :
              <>

                <div
                  class="text-lg mr-2 p-1 cursor-pointer"
                  onClick={() => openLoginPrompt()}
                >

                  <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;{" "}
                  &nbsp;Save


                </div>
              </>}


            <div>
              <button
                className=" text-base htextcolor px-2 mr-2 "
                onClick={() => {
                  setShare(!share);
                }}
              >
                <i class="fa fa-share" aria-hidden="true"></i>
                &nbsp;Share
              </button>

            </div>
          </div>
          {Sharelogo}
        </div >
        <hr></hr>

        <Link
          onClick={() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }}
          to={`/QnA/${questiondata?._id}`}
        >
          {answer}
        </Link>
      </div >
      <br></br>

      {loginPrompt && (
        <LoginPromt toggleLoginPrompt={() => toggleLoginPrompt()} />
      )}
    </>
  );
}

export default QuestionCard;
