import Anshul from "../assets/images/common/anshul.jpeg";
import Avnish from "../assets/images/common/avnish.jpeg";
import Kartick from "../assets/images/common/kartick.jpeg";
import Kush from "../assets/images/common/kush.jpeg";
import Madhav from "../assets/images/common/madhav.jpg";
import Raj from "../assets/images/common/raj.jpeg";
import Sumit from "../assets/images/common/sumit.jpeg";

function TeamSuvidha() {
    const info = [
        {
            name: "Anshul Sharma",
            imgSrc: Anshul,
            instalink: "https://www.instagram.com/iam_anshul_sharma/",
            linkedInlink: "https://www.linkedin.com/in/anshulsharma999/",
            gmaillink: "mailto:sharma12ansh@gmail.com"
            
          },
          {
            name: "Avnish Tripathi",
            imgSrc: Avnish,
            instalink: "https://www.instagram.com/avnish_0207/",
            linkedInlink: "https://www.linkedin.com/in/avnish-tripathi-4559a5196/",
            gmaillink: "mailto:avnishtripathi55@gmail.com"
            
          },
          {
            name: "Kartick Verma",
            imgSrc: Kartick,
            instalink: "https://instagram.com/kartick.verma",
            linkedInlink: "https://www.linkedin.com/in/kartick-verma",
            gmaillink: "mailto:kvkartickverma83@gmail.com"
            
          },
          {
            name: "kush Shah",
            imgSrc: Kush,
            instalink: "https://instagram.com/kush.shah13?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
            linkedInlink: "https://www.linkedin.com/in/kush-shah-495109192",
            gmaillink: "kushshah1503@gmail.com"
            
          },
          {
            name: "Madhav Maheshwari",
            imgSrc: Madhav,
            instalink: "https://www.instagram.com/madhavm0706/",
            linkedInlink: "https://www.linkedin.com/in/madhav-maheshwari-b20281193/",
            gmaillink: "mailto:madhavm0706@gmail.com"
            
          },
          {
            name: "Raj Kumar",
            imgSrc: Raj,
            instalink: "https://www.instagram.com/dk_raj71/",
            linkedInlink: "https://www.linkedin.com/in/raj-kumar-1029/",
            gmaillink: "mailto:roopak1029@gmail.com"
            
          },
          {
            name: "Sumit Chaudhary",
            imgSrc: Sumit,
            instalink: "",
            linkedInlink: "https://www.linkedin.com/in/sumitchaudhary14",
            gmaillink: "mailto:chaudharysumit130@gmail.com"
            
          }
    ];
  return (
    <>
        <div>
            <div className="container my-24 mx-auto md:px-6">
                {/* <!-- Section: Design Block --> */}
                    <section className="mb-32 text-center">
                    <h2 className="text-3xl font-bold mb-5 md:mb-8">Team Campus Suvidha</h2>


                        <div className="lg:gap-xl-12 grid gap-x-6 md:grid-cols-2 lg:grid-cols-4">
                            <div className="mb-12 lg:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px]" src={info[0].imgSrc} alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[0].name}</h5>
                                {/* <!-- <p className="mb-6">Frontend Developer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[0].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>

                                <a target="_blank" href={info[0].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>

                                <a target="_blank" href={info[0].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                
                                </ul>
                            </div>

                            <div className="mb-12 lg:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px]" src={info[1].imgSrc} alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[1].name}</h5>
                                {/* <!-- <p className="mb-6">Backend Developer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[1].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[1].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>

                                <a target="_blank" href={info[1].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                
                                </ul>
                            </div>

                            <div className="mb-12 lg:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px] h-[150px] " src={info[2].imgSrc}  alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[2].name}</h5>
                                {/* <!-- <p className="mb-6">Digital Marketing Analyst</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[2].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[2].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>

                                <a target="_blank" href={info[2].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                
                                </ul>
                            </div>

                            <div className="mb-12 md:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px]" src={info[3].imgSrc} alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[3].name}</h5>
                                {/* <!-- <p className="mb-6">Web Designer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[3].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[3].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[3].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                </ul>
                            </div>
                            <div className="mb-12 md:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px] h-[150px]" src={info[4].imgSrc}  alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[4].name}</h5>
                                {/* <!-- <p className="mb-6">Web Designer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[4].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                                </a>
                                <a target="_blank" href={info[4].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[4].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                </ul>
                            </div>

                            <div className="mb-12 md:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px]" src={info[5].imgSrc} alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[5].name}</h5>
                                {/* <!-- <p className="mb-6">Web Designer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[5].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[5].linkedInlink} className="px-2 py-1">
                                    {/* <!-- linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[5].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                </ul>
                            </div>

                            <div className="mb-12 md:mb-12">
                                <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 w-[150px] h-[150px]" src={info[6].imgSrc} alt="avatar" />
                                <h5 className="mb-4 text-lg font-bold">{info[6].name}</h5>
                                {/* <!-- <p className="mb-6">Web Designer</p> --> */}
                                <ul className="mx-auto flex list-inside justify-center">
                                <a target="_blank" href={info[6].instalink} className="px-2 py-1">
                                    {/* <!-- Instagram --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-4 w-4 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                                </a>
                                <a target="_blank" href={info[6].linkedInlink} className="px-2 py-1">
                                    {/* <!-- Linkedin --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    className="h-3.5 w-3.5 text-primary dark:text-primary-400">
                                    <path fill="currentColor"
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a target="_blank" href={info[6].gmaillink} className="px-2">
                                    {/* <!-- Gmail --> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32">
                                    <path d="M 6 7.03125 C 5.691406 7.03125 5.402344 7.089844 5.125 7.175781 L 8.039063 9.03125 L 23.960938 9.03125 L 26.875 7.175781 C 26.597656 7.089844 26.308594 7.03125 26 7.03125 Z M 4.113281 7.71875 C 3.4375 8.269531 3 9.097656 3 10.03125 L 3 23.03125 C 3 24.683594 4.347656 26.03125 6 26.03125 L 26 26.03125 C 27.652344 26.03125 29 24.683594 29 23.03125 L 29 10.03125 C 29 9.097656 28.5625 8.269531 27.886719 7.71875 L 16 15.28125 Z M 6 12.445313 L 16 18.8125 L 26 12.445313 L 26 24.03125 L 6 24.03125 Z"></path>
                                    </svg>
                                </a>
                                </ul>
                            </div>

                        
                        </div>
                    </section>
                {/* <!-- Section: Design Block --> */}
            </div>
        </div>
    </>

    );
}

export default TeamSuvidha;