// import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import authService from "../services/authService";

const RoleAccess = ({ roles = [] }) => {
  // const navigate = useNavigate();
  const user = authService.getRole();

  // function isProfileUpdated() {
  //   const isProfileUpdated = localStorage.getItem("profile");

  //   if (isProfileUpdated === "false") {
  //     return navigate("/update-profile", {
  //       state: { promptText: "Please update your profile!" },
  //     });
  //   }
  // }

  // useEffect(() => {
  //   isProfileUpdated();
  // }, []);

  return !roles.length || roles.includes(user) ? (
    <Outlet />
  ) : (
    <Navigate to="/404" replace />
  );
};

export default RoleAccess;
