import { useEffect, useState } from "react";
import Loader from "../../common/loader";
import AutoService from "../../services/AutoServices";
// import RequestCard from "./RequestCard";
import QuestionCard from "./QuestionCard";
import QnAService from "../../services/QnAService";

function MyQuestions(params) {
  const [loader, setLoader] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getMyProducts = async () => {
      setLoader(true);
      const res = await QnAService.getQuestionsMe();
      console.log(res.data.questions);
      setProducts(res.data.questions);
      setLoader(false);
    };

    getMyProducts();
  }, []);

  return (
    <>
      <div className="mx-5 my-10 md:mx-20">
        <div className="text-2xl font-bold text-center w-full mb-5">
          My Saved Questions
        </div>

        {loader ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1   w-full mb-10">
                      {products?.map((product, key) => (
                        <div class="flex justify-center items-center " key={key}>
                          <QuestionCard
                            questiondata={product}

                          />
                        
                          
                        </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MyQuestions;
