import React, { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import Loader from "../../common/loader";
import LoginPromt from "../../container/loginPrompt";
import AutoService from "../../services/AutoServices";
import QnAService from "../../services/QnAService";
// import RequestCard from "./RequestCard";
import QuestionCard from './QuestionCard';


function QnAHome() {
  const [loader, setLoader] = useState(true);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [readMore, setReadMore] = useState(false);
  const [date, setdate] = useState("");
  const [from, setfrom] = useState("")
  const [to, setto] = useState("")
  const [loginPrompt, setLoginPrompt] = useState(false);
  const limit = 12;

console.log(products)

  const getProducts = async () => {
    setReadMore(true);
    const res = await QnAService.getQuestions(
      page,
      limit,
    );

    setProducts((prev) => [...prev, ...res.data.data]);
    setMaxPages(res.data.totalPages);

    setLoader(false);
    setReadMore(false);
  };

  const filterProducts = async () => {
    setLoader(true);
    setProducts([]);
    const res = await AutoService.getCabDetails(
      page,
      limit,
     

    );

    setProducts((prev) => [...res.data.data]);
    setMaxPages(res.data.totalPages);
    setLoader(false);
    setReadMore(false);
  };



  useEffect(() => {
    getProducts();
  }, [page]);

  const handleLoadMore = () => {
    setReadMore(true);
    if (page < maxPages) {
      setPage(page + 1);
    }
    setReadMore(false);
  };

  const toggleLoginPrompt = () => {
    console.log("toggle");
    setLoginPrompt(false);
  };

  const openLoginPrompt = () => {
    if (!localStorage.getItem("token")) {
      setLoginPrompt(true);
      return;
    }
  };

  return (
    <>
      <div className="">
        <div className="flex mx-3 mb-5 pt-3 text-center">
          <div className="text-2xl font-bold w-full">Questions & Answer</div>
        </div>

        <div className="text-center font-light text-md md:text-lg mx-10 my-5">
          "!! Presenting a platform to remove any Question Mark that annoys you.
Get Answers from Friends, Seniors and Alumni !!"
        </div>



        <div className="flex justify-center text-center text-sm pb-4 md:px-10">
          {localStorage.getItem("token") ? (
            <>
              <Link
                to="/QnA/myQuestions"
                className="bg-indigo-600 hover:bg-indigo-800 text-white font-bold px-6 py-2 rounded-full whitespace-nowrap"
              >
                Saved Questions
              </Link>
              <Link
                to="/QnA/PostQuestion"
                className="bg-indigo-600 hover:bg-indigo-800 text-white font-bold px-6 mx-5 py-2 rounded-full whitespace-nowrap"
              >
                Add Question
              </Link>
              
            </>
          ) : (
            <>
              <div
                onClick={() => openLoginPrompt()}
                className="cursor-pointer bg-indigo-600 hover:bg-indigo-800 text-white font-bold px-6 mx-5 py-2 rounded-full whitespace-nowrap"
              >
                Add Question
              </div>
              
              <div
                onClick={() => openLoginPrompt()}
                className="cursor-pointer bg-indigo-600 hover:bg-indigo-800 text-white font-bold px-6 py-2 rounded-full whitespace-nowrap"
              >
                Saved Questions
              </div>
            </>
          )}
        </div>

        {loginPrompt && (
          <LoginPromt toggleLoginPrompt={() => toggleLoginPrompt()} />
        )}

        <div className="flex justify-center mx-2 my-1  mb-10">
          {/* <div class="flex flex-col md:flex-row items-center space-x-4">
            <div class="relative">
              <label for="datepicker" class="block text-gray-700 font-medium">.</label>
              <input
                value={date}
                onChange={(e) => setdate(e.target.value)}
                required
                placeholder="Type to search"
                type="text"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            </div>
            
            <button onClick={() => filterProducts()} class="bg-indigo-600 mt-6 hover:bg-indigo-600 text-white font-medium py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">Search</button>
          </div> */}

        </div>

        <div className="">
          <div>
            <div className="text-center text-2xl my-3 font-bold">
              Recent Asked Questions
            </div>
          </div>
          <div className="mx-5">
            {loader ? (
              <Loader></Loader>
            ) : (
              <>
                {products?.length === 0 && loader === false ? (
                  <>
                    <div className="text-center w-full text-lg mt-10">
                      No Requests to list!!!
                    </div>
                  </>
                ) : (
                  <>
                    <div className="grid grid-cols-1   w-full mb-10">
                      {products?.map((product, key) => (
                        <div class="flex justify-center items-center " key={key}>
                          <QuestionCard
                            questiondata={product}

                          />
                        
                          
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {readMore ? (
                  <div className="flex justify-center mb-10">
                    <Loader />
                  </div>
                ) : (
                  page < maxPages && (
                    <div className="flex justify-center mb-10">
                      <button
                        className="bg-indigo-600 hover:bg-indigo-800 text-white font-bold mt-10 px-6 py-2 rounded-full whitespace-nowrap"
                        onClick={() => {
                          setReadMore(true);
                          handleLoadMore();
                        }}
                      >
                        Load More
                      </button>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QnAHome;