import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/common/logo.png";
import Loader from "../../common/loader";
import authService from "../../services/authService";
import axios from 'axios'

function Login() {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    setError("");

    try {
      const res = await authService.login(email, password, rememberMe);
      console.log(res);
      if (res.success) {
        if( res.user.isverified === false)  {
          setTimeout(() => {
            navigate("/verify-account");
            setLoader(false);
          }, 1000);
        }
        else if( res.user.isprofileupdated === false){
          setTimeout(() => {
            navigate("/update-profile");
            setLoader(false);
          }, 1000);
        }
        else if (res.user.role === "user") {
          setTimeout(() => {
            navigate("/dashboard");
            setLoader(false);
          }, 1000);
        }
      } else {
        setError("Something went wrong!");
      }
    } catch (error) {
      setLoader(false);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong!");
      }
    }
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 min-h-[93vh]">
        <div className="flex flex-col items-center justify-center px-6 py-8 my-auto">

          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 className="mb-1 text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign In
            </h2>
            <form
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>

              <div className="flex flex-wrap mt-5">
                <label className="inline-flex w-1/2 items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    className="cursor-pointer form-checkbox border-0 rounded ml-1 w-5 h-5"
                    onChange={(e) => setRememberMe(e.target.checked)}
                    value={rememberMe}
                    defaultChecked
                  />
                  <span className="ml-2 text-sm md:text-sm text-gray-900 font-semibold dark:text-white">
                    Remember me
                  </span>
                </label>
                <Link
                  to="/resetPassword"
                  className="inline-flex w-1/2 justify-end font-semibold cursor-pointer"
                >
                  <div className="text-gray-900 dark:text-white">
                    <small>Forgot password?</small>
                  </div>
                </Link>
              </div>
              {error && (
                <>
                  <div className="text-center text-red-500">{error}</div>
                </>
              )}
              <button
                type="submit"
                className="w-full  bg-indigo-600 text-white  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {loader ? <Loader height={5} width={5} /> : "Sign In"}
              </button>

              <Link
                to="/register"
                className="flex flex-wrap mt-3 justify-center cursor-pointer font-semibold"
              >
                <div className="text-center text-lg text-gray-900 dark:text-white">
                  <small>Create new account</small>
                </div>
              </Link>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;
