import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./container/home";
import Dashboard from "./container/dashboard";
import Profile from "./container/profile";
import Navbar from "./container/navbar";  
import Logout from "./container/logout";
import RoleAccess from "./common/roleAccess";
import BASHome from "./components/buyAndSell/basHome";
import ProductDescription from "./components/buyAndSell/productDescription";
import ProductDescriptionMe from "./components/buyAndSell/productDescriptionMe";
import MyPosts from "./components/buyAndSell/myPosts";
import AddProduct from "./components/buyAndSell/addProduct";
import Page404 from "./container/page404";
import UpdateProfile from "./components/profile/updateProfile";
import RoleAccessProfile from "./common/roleAccessProfile";
import VerifyAccount from "./components/login/verifiyAccount";
import Login from "./components/login/login";
import Register from "./components/login/register";
import UpdatePassword from "./components/login/updatePassword";
import ResetPassword from "./components/login/resetPassword";
// import MessMenu from "./container/menu";
import ComingSoon from "./container/comingSoon";
import ResetPasswordLink from "./components/login/resetPasswordLink";
import ASHome from "./components/AutoShare/AutoHome";
import AddRequest from "./components/AutoShare/addRequest";
import MyRequests from "./components/AutoShare/myRequests";
import PostQuestion from "./components/QnA/PostQuestions";
import QnAHome from "./components/QnA/QnA";
import Answer from './components/QnA/Answer';
import MyQuestions from './components/QnA/MyQuestions';
import TeamSuvidha from "./container/ourTeam";
function App() {

  // console.log = function(){};

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/resetPassword" element={<ResetPassword/>} />
        <Route path="/password/reset/:token" element={<ResetPasswordLink/>} />
        {/* <Route path="/mess" element={<MessMenu/>} /> */}
        <Route element={<RoleAccess roles={["user"]} />}>
        <Route path="/updatePassword" element={<UpdatePassword/>} />
          <Route path="/verify-account" element={<VerifyAccount/>} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/buyAndSell/addPost" element={<AddProduct />} />
          <Route path="/buyAndSell/myPosts" element={<MyPosts />} />
          <Route path="/buyAndSell/editPost/:id" element={<AddProduct />} />

          <Route path="/autoShare/addRequest" element={<AddRequest />} />
          <Route path="/autoShare/myRequests" element={<MyRequests />} />
          <Route path="/autoShare/editRequest/:id" element={<AddRequest />} />
          
          <Route path="/QnA/postQuestion" element={<PostQuestion />} />
          <Route path="/QnA/myQuestions" element={<MyQuestions />} />
          <Route path="/QnA/editQuestion/:id" element={<PostQuestion />} />

          
          <Route path="/canteen" element={<ComingSoon />} />
        </Route>
        <Route element={<RoleAccessProfile roles={["user"]} />}>
          <Route path="/update-profile" element={<UpdateProfile/>} />
          <Route path="/logout" element={<Logout />} />
        </Route>
        {/* No Authentication Required */}
        <Route path="/buyAndSell" element={<BASHome />} />
        <Route path="/buyAndSell/:id" element={<ProductDescription />} />
        <Route path="/buyAndSell/product-me/:id" element={<ProductDescriptionMe />} />
        <Route path="/autoShare" element={<ASHome />} />
        <Route path="/QnA" element={<QnAHome />} />
        <Route path="/QnA/:id" element={<Answer />} />
        {/* <Route path="/ourTeam" element={<TeamSuvidha />} /> */}




        
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}

export default App;
