import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../common/loader";
import QnAService from "../../services/QnAService";
import AutoService from "../../services/AutoServices";
function PostQuestion(props) {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [postLoading, setPostLoading] = useState(false);
    const [question, setQuestion] = useState("");


    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(id ? true : false);


    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!question) {
            setError("Please fill all the fields");
            return;
        }

        setError("");
        setSuccess("");

        

        try {
            const reqdata = {
                question:question
            }
            await QnAService.postQuestion(reqdata);
            setSuccess("Requested successfully");
            setQuestion("")


        } catch (err) {
            // if (er.response && er.response.status === 400) {
            //   console.log(er.response.data);
            // }
            if (err.response) {
                setError(err.response.data.message);
                console.log(err.response);
            } else {
                setError("Something went wrong");
                console.log(err);
            }

            return;
        }
    };



    const editRequest = async (e) => {
        console.log("jkjkj")
        setPostLoading(true);
        e.preventDefault();
        const reqdata = {
            question:question
        }



        try {
            const res = await QnAService.updateQuestion(id, reqdata);
            console.log(res)
            if (res.data.success) {
                setSuccess("Question is successfully updated !!!");
            }
            setPostLoading(false);
        } catch (error) {
            setPostLoading(false);
        }
    };

    useEffect(() => {
        const getrequest = async () => {
            if (id) {
                setIsEdit(true);
                const res = await QnAService.getPaticularQuestion(id);
                console.log(res.data)
                setQuestion(res.data.question[0].question)
            }
        };

        getrequest();
    }, [id]);







    return (
        <>
            <div className="container my-12 px-6 mx-auto">
                <section className="mb-32 text-center">
                    <div className="max-w-[700px] mx-auto px-3 lg:px-6">
                        <h2 className="text-3xl font-bold mb-12">Add Your Question Here</h2>
                        <form onSubmit={(e) => (isEdit ? editRequest(e) : handleSubmit(e))} >
                            <div class="form-group mb-6">
                                <textarea
                                    type="number"
                                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    placeholder="Type your Question"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                />
                            </div>
                            


                            <div>
                                <button
                                    type="submit"
                                    className="w-full px-6 py-2.5 bg-indigo-600 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg transition duration-150 ease-in-out"
                                    disabled={postLoading}
                                >
                                    {postLoading ? <Loader height={5} width={5} /> : "Post"}
                                </button>
                            </div>
                            {error && (
                                <div className="text-center text-red-500 text-lg my-5">
                                    {error}
                                </div>
                            )}

                            {success && (
                                <div className="text-center text-green-500 text-lg my-5">
                                    {success}
                                </div>
                            )}
                        </form>
                    </div>
                </section>
            </div>
        </>
    );
}

export default PostQuestion;
