import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../common/loader";
import QnAService from "../../services/QnAService";
import AutoService from "../../services/AutoServices";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getId } from "../../services/authService";
import LoginPromt from "../../container/loginPrompt";

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
} from "react-share";
import { FacebookIcon, WhatsappIcon, EmailIcon } from "react-share";
function Answer(props) {





    const [save, setSave] = useState()

    const [products, setProducts] = useState([]);
    const [copy, setCopy] = useState(false);
    const [loginPrompt, setLoginPrompt] = useState(false);

    const [isUpvoted, setisUpvote] = useState(false);
    const [loader, setLoader] = useState(true);
    const [share, setShare] = useState(false);

    const { id } = useParams();
    useEffect(() => {
        const getMyProducts = async () => {
            setLoader(true);
            const res = await QnAService.getPaticularQuestion(id);
            console.log(res.data.question);
            setProducts(res.data.question[0]);
            setSave(res.data.question[0]?.bookmark.includes(getId()) ? true : false)
            setLoader(false);
        };

        getMyProducts();
    }, []);


    const [editorstate, setEditorState] = React.useState(
        () => EditorState.createEmpty()
    );

    const PostAnswer = async () => {
        setLoader(true);
        const data = {
            answer: draftToHtml(convertToRaw(editorstate.getCurrentContent()))
        }
        const res = await QnAService.AddAnswer(id, data);
        console.log(res.data);
        setProducts(res.data.result)
        setEditorState(EditorState.createEmpty());
        setLoader(false);
    };

    const saveQues = async (questionid) => {

        const res = await QnAService.saveQuestion({ questionid });
        console.log(res.data)


        setSave(!save);
        // setNoOfRiders(res.data.cab[0].no_of_riders)

    };

    let Sharelogo;
    if (share) {
        Sharelogo = (
            <>
                <div className="flex justify-end mt-5">
                    <div className="pr-4 pl-4  ">
                        <WhatsappShareButton
                            title={products?.question}
                            url={`https://www.campussuvidha.com/QnA/${products?._id}`}
                        >
                            <WhatsappIcon size={30}></WhatsappIcon>
                        </WhatsappShareButton>
                    </div>
                    <div className="pr-4 pl-4  ">
                        <FacebookShareButton
                            title={products?.question}
                            url={`https://www.campussuvidha.com/QnA/${products?._id}`}
                        >
                            <FacebookIcon size={30}></FacebookIcon>
                        </FacebookShareButton>
                    </div>
                    <div className="pr-4 pl-4  ">
                        <EmailShareButton
                            title={products?.question}
                            url={`https://www.campussuvidha.com/QnA/${products?._id}`}
                        >
                            <EmailIcon size={30}></EmailIcon>
                        </EmailShareButton>
                    </div>
                    <div className="pr-4 pl-4  ">
                        <CopyToClipboard
                            text={`https://www.campussuvidha.com/QnA/${products?._id}`}
                            onCopy={() => {
                                setCopy(true);
                            }}
                        >
                            <button>
                                <i class="fa fa-copy"></i>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </>
        );
    }

    const _uploadImageCallBack = (file) => {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://api.imgur.com/3/image');
                xhr.setRequestHeader('Authorization', 'Client-ID 3ed8eeafe9c0857');
                const data = new FormData();
                data.append('image', file);
                xhr.send(data);
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            }
        );
    }



    console.log(draftToHtml(convertToRaw(editorstate.getCurrentContent())))

    const toggleLoginPrompt = () => {
        console.log("toggle");
        setLoginPrompt(false);
      };
    const openLoginPrompt = () => {
        if (!localStorage.getItem("token")) {
          setLoginPrompt(true);
          return;
        }
      };



    return (
        <>
            {loader ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1   w-full mb-10">
                        <div className="flex mx-3 mb-5 pt-3 text-center">
                            <div className="text-2xl font-bold w-full">Questions & Answer</div>
                        </div>


                        <div class="flex justify-center items-center " >
                            <div class="md:w-2/3 mb-10 w-full flex animationtop flex-col bg-white shadow-2xl rounded-lg overflow-hidden p-3">
                                <div className="mb-1">

                                    <div className="flex justify-between ">
                                        <div className="flex items-center">



                                            <div className="flex ">
                                                <img src={products.user.image} alt="Profile Pic" class=" object-cover w-10 h-10 mx-4 rounded-full sm:block" />

                                                <p class="font-bold text-gray-700 cursor-pointer dark:text-gray-200">{products?.user.name}
                                                    <p class="text-sm text-gray-400">{products?.user.email}</p>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-right">


                                        </div>
                                    </div>
                                    <div class="font-black text-xl  mt-5">
                                        {products.question}{" "}
                                        <span className="text-2xl font-bold text-red-600">?</span>
                                    </div>
                                    <div class="text-xs mt-2 textcolor font-bold font-semibold items-center underline">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        &nbsp;Answers: &nbsp;{products.answer.length}
                                        &nbsp;&nbsp;&nbsp;Posted-on: &nbsp;
                                        {products.date.substring(0, 10)}
                                    </div>

                                    <br></br>
                                    <hr></hr>
                                    <div className="flex justify-between  mt-2">



                                        {/* {questiondata.bookmark.includes() ? (
                            <div
                                class="text-lg mr-2 p-1 cursor-pointer"
                            // onClick={() => unbookmark()}
                            >
                                <i class="fa fa-bookmark" aria-hidden="true"></i>&nbsp;{" "}
                                &nbsp;Saved
                            </div>
                        ) : ( */}
                                        {/* <div
                                    class="text-lg mr-2 p-1 cursor-pointer"
                                // onClick={() => addtobookmark()}
                                >
                                    <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                    &nbsp;Save
                                </div> */}
                                        {/* )} */}

                                        {localStorage.getItem("token") ?
                                            <>
                                                <div
                                                    class="text-lg mr-2 p-1 cursor-pointer"
                                                    onClick={() => saveQues(products?._id)}
                                                >

                                                    {save ? <>
                                                        <i class="fa fa-bookmark" aria-hidden="true"></i>&nbsp;{" "}
                                                        &nbsp;Saved
                                                    </> : <>
                                                        <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;{" "}
                                                        &nbsp;Save
                                                    </>}

                                                </div>
                                                <div
                                            class="text-lg mr-2 p-1 cursor-pointer"
                                            onClick={
                                                () => {
                                                    var ans = document.getElementById("answer");

                                                    ans.style.display = "block"
                                                }
                                            }
                                        // onClick={() => addtobookmark()}
                                        >
                                            <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                                            <span className="cursor-pointer">
                                                Click to Answer

                                            </span>
                                        </div>
                                            </> :
                                            <>

                                                <div
                                                    class="text-lg mr-2 p-1 cursor-pointer"
                                                    onClick={() => openLoginPrompt()}
                                                >

                                                    <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;{" "}
                                                    &nbsp;Save


                                                </div>
                                                <div
                                            class="text-lg mr-2 p-1 cursor-pointer"
                                            onClick={
                                                () => openLoginPrompt()
                                            }
                                        // onClick={() => addtobookmark()}
                                        >
                                            <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                                            <span className="cursor-pointer">
                                                Click to Answer

                                            </span>
                                        </div>
                                            </>}


                                   
                                        <div>




                                            <button
                                                className=" text-base htextcolor px-2 mr-2 "
                                                onClick={() => {
                                                    setShare(!share);
                                                }}
                                            >
                                                <i class="fa fa-share" aria-hidden="true"></i>
                                                &nbsp;Share
                                            </button>

                                        </div>
                                    </div>
                                    {Sharelogo}
                                </div >
                                <hr></hr>

                                {/* <Link
                    onClick={() => {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }}
                    to={`/college-canteen-QandA/${questiondata.club}/${questiondata._id}`}
                >
                    {answer}
                </Link> */}
                                <div
                                    className="my-2 mx-5"
                                    style={
                                        {
                                            display: "none"
                                        }
                                    }
                                    id="answer"><form action="POST" method="POST">
                                        <label class="block text-gray-700 text-sm font-bold mb-2" for="question">
                                            Write you answer here:
                                        </label>
                                        <div className="col-span-12 sm:col-span-12">
                                            <div className="editorbox border" style={{ width: "100%", minHeight: "300px" }}>
                                                <Editor
                                                    editorClassName="demo-editor"
                                                    wrapperClassName="demo-wrapper"

                                                    toolbar={{
                                                        image: {
                                                            uploadCallback: _uploadImageCallBack,
                                                            alt: { present: true, mandatory: false },
                                                            previewImage: true,
                                                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
                                                        },
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: true },
                                                        link: { inDropdown: true },
                                                        history: { inDropdown: true },

                                                    }}
                                                    editorState={editorstate}
                                                    onEditorStateChange={(e) => setEditorState(e)}
                                                />

                                            </div>


                                        </div>
                                        <div className="">


                                            <br></br>






                                            <div class="flex items-center justify-between mt-2">
                                                <button type="submit" class="bg-blue-500 hover:bg-green-400 text-white hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => { PostAnswer() }}>
                                                    Post
                                                </button>
                                                <button
                                                    onClick={
                                                        () => {
                                                            var ans = document.getElementById("answer");
                                                            ans.style.display = "none"
                                                        }
                                                    }
                                                    class="bg-red-600 hover:bg-gray-800 text-white hover:text-gray-300 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div></form>

                                </div>

                                <br></br>

                                Answered By:

                                {products?.answer.length == 0 ?

                                    <>
                                        <div class="text-base text-white border rounded-lg p-1 mt-5   text-white bg-indigo-600 text-center">
                                            Be the first One to answer this question
                                        </div>
                                    </>
                                    :
                                    <>
                                        {products.answer.map((data) => {
                                            return (
                                                <div className="p-5">
                                                    <div className="flex mb-3 items-center">
                                                        <img src={data.ansUser.image} alt="Profile Pic" class=" object-cover w-10 h-10 mx-4 rounded-full sm:block" />
                                                        <div className="flex flex-col">
                                                            <span className="text-sm textcolor font-black">
                                                                {data.ansUser.name}
                                                            </span>
                                                            <span className="text-sm">
                                                                {" "}
                                                                {data.ansUser.email}{" "}
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div class="text-xs mt-2 textcolor font-bold font-semibold items-center ">
                                                        &nbsp;&nbsp;&nbsp;Answered-on: &nbsp;
                                                        {data?.ansDate.substring(0, 10)}
                                                    </div>
                                                    <div
                                                        className="h-auto overflow-y-hidden box text-sm"
                                                        style={{ minHeight: "30px" }}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: data.answerDes }}></div>

                                                    </div>
                                                </div>
                                            )
                                        }).reverse()}

                                    </>
                                }
                            </div >
                            <br></br>




                        </div>
                    </div>

                    {loginPrompt && (
        <LoginPromt toggleLoginPrompt={() => toggleLoginPrompt()} />
      )}
                </>
            )}
        </>
    );
}

export default Answer;
