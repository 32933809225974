import httpService from "./httpService";



function postQuestion(data) {
    return httpService.post("/QnA/post-question", data);

}


export function getQuestions(page,limit){
    return httpService.get(`/get-QnA?page=${page}&limit=${limit}`);

}

// export function DeleteQuestion(data){
//     return httpService.delete("/delete-question",{data : data})
// }

export function getQuestionsMe(){

    return httpService.get(`/get-questions-me`)
  }

  function AddAnswer(id,data){
    return httpService.put(`/QnA/add-answer/${id}`,data);
}


export function getPaticularQuestion(id){
    return httpService.get(`/QnA/${id}`);


}

function updateQuestion(id, data) {

    let updateData = {
        requestid: id,
        data: {
            ...data
        }
    };
    
    return httpService.put(`/update-question`, updateData);
}
function saveQuestion(data) {

   
    
    return httpService.put(`/save-question`, data);
}

const QnAService = {postQuestion,getQuestions,getQuestionsMe,getPaticularQuestion,AddAnswer,saveQuestion,updateQuestion};

export default QnAService;