import React, { useState } from "react";
import { Link } from "react-router-dom";
import authService from "../../services/authService";
import buyAndSellService from "../../services/buyAndSellService";
import Loader from "../../common/loader";
import LoginPromt from "../../container/loginPrompt";
import { getId } from '../../services/authService';
import AutoService from "../../services/AutoServices";

function RequestCard({ cabdata }) {
    const [loader, setLoader] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [enquiry, setEnquiry] = useState(false);
    const [enquiryData, setEnquiryData] = useState("");
    const [enquiryLoader, setEnquiryLoader] = useState(false);
    const [loginPrompt, setLoginPrompt] = useState(false);

    const toggleLoginPrompt = () => {
        console.log("toggle");
        setLoginPrompt(false);
    };

    const deleteProduct = async (id) => {
        setLoader(true);
        const data = {
          requestid : `${id}`
        };
    
        try {
          const res = await AutoService.DeleteRequest(data);
          console.log(res)
          
          if(res.data.success){
            setDeleted(true);
          }
          setLoader(false);
        } catch (error) {
          setLoader(false);
        }
      }

    const enquireProduct = async (id) => {

        if (!localStorage.getItem('token')) {
            setLoginPrompt(true);
            return;
        }

        if (enquiryData.length < 10) {
            setEnquiryLoader(true);
            const res = await AutoService.getRequestEnquire(id);
            console.log(res.data);
            if (res.data.phoneNo) {
                setEnquiryData(res.data.phoneNo);
                setEnquiry(true);
                setEnquiryLoader(false);
            }
        }
    };

    function getDateXDaysAgo(date) {
        const currentDate = new Date();

        let diff = currentDate - new Date(date);

        let diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    

    return (
        <>
            <div class="max-w-2xl px-8 py-4 m-2 mb-5 bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div class="flex items-center justify-between">
                    <span class="text-sm md:text-lg  text-gray-600 dark:text-gray-400"><strong>From </strong>{cabdata?.placeinfo.location.from} <strong>To</strong> {cabdata?.placeinfo.location.to}</span>
                    <button class="px-3 py-1 text-xs md:text-sm font-bold text-gray-100 bg-indigo-600 rounded ">Passenger Required -{cabdata?.no_of_riders} </button>

                </div>
                <div className='mt-2'>
                    <p class="md:text-xl  text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"><strong>Date Of Journey - </strong> {cabdata?.placeinfo.Date}</p>
                </div>
                <br></br>

                <div class="flex items-center mt-2 space-x-2">

                    <span class="inline-flex items-center leading-none px-2.5 py-1.5 text-sm font-medium text-skin-inverted rounded-full border border-skin-input dark:text-white">

                        <svg class="mr-1.5 h-2 w-2 brand-react" fill="currentColor" viewBox="0 0 8 8">

                            <circle cx="4" cy="4" r="3"></circle>

                        </svg>
                        Time- {cabdata?.placeinfo.time}
                    </span>
                    <span class="inline-flex items-center leading-none px-2.5 py-1.5 text-sm font-medium text-skin-inverted rounded-full border border-skin-input dark:text-white">

                        <svg class="mr-1.5 h-2 w-2 brand-react" fill="currentColor" viewBox="0 0 8 8">

                            <circle cx="4" cy="4" r="3"></circle>

                        </svg>
                        Cab Type - {cabdata?.transport}
                    </span>
                </div>
                <div class=" items-center justify-between mt-4">

                    {authService.getCurrentUser() === cabdata?.userid
                        ?
                        <>
                            <div className="grid grid-cols-2 gap-4 h-12">
                                <Link to={`/autoShare/editRequest/${cabdata?._id}`}>
                                    <div className="flex justify-center">
                                        <button className="m-2 p-2 bg-indigo-600 text-white hover:bg-indigo-800 text-m font-bold rounded-full w-full">
                                            Edit
                                        </button>
                                    </div>
                                </Link>

                                <div className="flex justify-center" onClick={() => deleteProduct(cabdata?._id)}>
                                    <button className={`m-2 p-2 ${deleted ? "bg-red-700 hover:bg-red-800" : "bg-indigo-600 hover:bg-indigo-800"} text-white text-m font-bold rounded-full w-full`}>
                                        {loader ? <Loader height={5} width={5} /> : deleted ? "Deleted" : "Delete"}
                                    </button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div class="flex items-center">
                                <img src={cabdata?.userid.image} alt="Profile Pic" class=" object-cover w-10 h-10 mx-4 rounded-full sm:block" />
                                <p class="font-bold text-gray-700 cursor-pointer dark:text-gray-200">{cabdata?.userid.name}
                                    <p class="text-sm text-gray-400">{cabdata?.userid.email}</p>
                                </p>

                            </div>
                            <button
                                onClick={() => enquireProduct(cabdata?._id)}
                                className="focus:ring-offset-2 flex items-center justify-center leading-none py-4 mt-10 m-4 bg-indigo-600 hover:bg-indigo-800 shadow-md hover:shadow-lg transition duration-150 ease-in-out focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg text-white text-m font-bold rounded-full w-11/12"
                            >
                                {enquiryLoader ? (
                                    <Loader height={5} width={5} />
                                ) : enquiry ? (
                                    `${enquiryData}`
                                ) : (
                                    "Enquire!"
                                )}
                            </button>
                        </>

                    }
                    {loginPrompt && <LoginPromt toggleLoginPrompt={() => toggleLoginPrompt()} />}

                </div>

            </div>

        </>
    );
}

export default RequestCard;
