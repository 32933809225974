import { Link } from "react-router-dom";
import authService from "../services/authService";

const ComingSoon = () => {
  return (
    <main className="h-screen overflow-y-clip w-full flex flex-col justify-center items-center bg-[#1A2238]">
      <h1 className="text-6xl md:text-9xl font-extrabold text-white tracking-widest">
        Coming Soon
      </h1>
      {/* <div className="bg-indigo-600 px-20 text-3xl rounded rotate-12 absolute">
		Canteen
	</div> */}
      <button className="mt-5">
        {authService.getRole() === "user" ? (
          <>
            {authService.getCurrentUser() ? (
              <>
                <Link
                  to="/dashboard"
                  className="relative inline-block text-sm font-medium text-indigo-600 group active:text-indigo-600 focus:outline-none focus:ring"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-indigo-600 group-hover:translate-y-0 group-hover:translate-x-0"></span>

                  <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
                    Go Dashboard
                  </span>
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/"
                  className="relative inline-block text-sm font-medium text-indigo-600 group active:text-indigo-600 focus:outline-none focus:ring"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-indigo-600 group-hover:translate-y-0 group-hover:translate-x-0"></span>

                  <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
                    Go Home
                  </span>
                </Link>
              </>
            )}
          </>
        ) : (
          <> </>
        )}
      </button>
    </main>
  );
};

export default ComingSoon;
