import httpService from "./httpService";
const apiEndpoint = "/register";
const tokenKey = "token";
const roleKey = "role";
const userid = "userid"

async function register(user) {
  const res = await httpService.post(apiEndpoint, {
    email: user.email,
    password: user.password,
    name: user.name,
  });

  if(res.data.success === true){
    localStorage.setItem(tokenKey, res.data.token);
    localStorage.setItem(roleKey, res.data.user.role);
    localStorage.setItem(userid, res.data.user._id);
  }

  return res;

}

function resendOtp() {
  return httpService.get("/generate-otp");
}

function verifyOtp(data) {
  return httpService.post("/verify-otp", data);
}

const registerService = { register, resendOtp, verifyOtp };

export default registerService;
